@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  min-width: 100%;
  min-height: 100vh;
}
.App {
  font-family: 'Montserrat', sans-serif;
  background-color: #f2f2f2;
  min-height: 100vh;
  overflow: hidden;
}
input::placeholder {
  color: rgb(5, 5, 5);
}
.active {
  background: #f2f2f2;
  color: black;
  font-weight: 700;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  position: relative;
}
.active::before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  background-color: #614285;
  right: 0;
  top: -30px;
  bottom: 100px;
  border-bottom-right-radius: 13px;
  box-shadow: 0px 17px 0px #f2f2f2;
}
.active:after {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  background-color: #614285;
  right: 0;
  top: 100%;

  border-top-right-radius: 13px;
  box-shadow: 0px -17px 0px #f2f2f2;
}
.app-container {
  display: flex;
  width: 100%;
}

th {
  color: #614285 !important;
  font-size: 0.7em;
}
td {
  font-size: 1em !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

/* stop chakra from applying shadow on checkbox */
.css-1c5vj91:focus {
  box-shadow: none !important;
}

.css-1c5vj91[data-focus] {
  box-shadow: none !important;
}

.css-mwso4b:focus,
.css-mwso4b[data-focus] {
  box-shadow: none !important;
}

/* chakra checkbox color when clicked */
.css-1ofo05q[aria-checked='true'],
.css-1ofo05q[data-checked] {
  background: #3cdb4c;
  border: 1px solid #3cdb4c;
}

.truncate {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vertical {
  width: 4px;
  height: 90px;
  background-color: #545353;
}
.notice-arrow {
  font-weight: 700;
  font-size: 26px;
  width: 30px;
  color: red;
}

.content {
  /* max-height: calc(100vh - 65px); */
  width: 100%;
}

/* override chakra ui tab list border bottom color */
.css-gnnwcj[aria-selected='true'] {
  /* color: #FC8181 !important; */
  border-color: #fc8181 !important;
}

.banner-container {
  background-color: #3cdb4c;
  height: 200px;
  position: relative;
}

.banner-delete-icon {
  position: absolute;
  top: 0px;
  opacity: 0;
  left: 0;
  height: 100px;
  width: 200px;
  padding: 10px;
  background-color: #9898983d;
  border-radius: 5px;
  display: grid;
  font-weight: 600;
  font-size: 14px;
  place-items: center;
  transition: all 0.5s ease-in-out;
}

.banner-container:hover .banner-delete-icon {
  top: 0;
  opacity: 1;
  transform: translate(15%, 50%);
}

.sidebar {
  z-index: 12;
  width: 220px;
  height: 100%;
}
.sidebar-inner {
  position: fixed;
  width: 220px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #614285;
  padding: 10px;
  padding-right: 0px;
  color: #fff;
}
.brand-style {
  font-size: 1em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 5px;
}

.link {
  display: flex;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 15px;
  padding: 5px;
  margin: 10px 0;
  font-size: 0.9em;
  align-items: center;
}

.close-side-bar {
  display: flex;
  justify-content: flex-start;
  margin: 15px;
}

.mobileStyle {
  position: fixed;
  bottom: 0;
  top: 0;
  box-shadow: 2px 2px 8px #a4a4a4;
}

.navigation-inner {
  background: #fff;
  border-radius: 3px;
  color: #a08ca8;
  font-weight: bold;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.toggle-side-bar {
  align-self: center;
}

.wallet-btn {
  border: 1px solid #b2b2b2;
  font-size: 12px;
  padding: 15px 25px;
  font-weight: 400;
  border-radius: 4px;
}

